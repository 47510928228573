import React from 'react';
import {useParams} from "react-router-dom"
import {templateAPI} from "../api/templateAPI";
import {Users} from "../components/Users";
import {WebmasterFlows} from "../components/WebmasterFlows";

export const Template = () => {
    const params = useParams()
    let alias = params?.alias

    const [content, setContent] = React.useState(null)

    React.useEffect(() => {
        let options = {
            "stat": {"periods": {"week": "01.10.2021 - 08.10.2021"}},
        }
        // if(alias === "teamlead")
        //     options = {...options, stat: {...options.stat, groups: ["flow_domain"]}}
        const getAliasTemplate = async (alias) => {
            let res = await templateAPI.getTemplateByAlias(alias, options)

            if (res && (alias === "teamlead" || alias === "affiliate")) {
                let users = res?.map(item => {
                    let user = item._user
                    return {
                        avatar: user.avatar,
                        email: user.email,
                        roles: user.roles,
                        id: user.id,
                        first_name: user.profile.first_name,
                        last_name: user.profile.last_name,
                        middle_name: user.profile.middle_name,
                        data: {
                            monitoring: 0,
                            missedReports: 0,
                            problem: 0,
                            good: 0
                        }
                    }
                })
                users && setData(users)
            } else if (res) {
                setData(res)
            }
        }
        if (alias) {
            getAliasTemplate(alias)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alias])

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (data) {
            switch (alias) {
                case "teamlead": {
                    setContent(<Users users={data}/>)
                    break
                }
                case "affiliate": {
                    setContent(<Users users={data}/>)
                    break
                }
                case "team": {
                    setContent(<Users users={data}/>)
                    break
                }
                case "stat_webmaster": {
                    setContent(<Users users={data}/>)
                    break
                }
                case "webmaster_flows": {
                    setContent(<WebmasterFlows data={data}/>)
                    break
                }
                default: {
                    setContent(<Users users={data}/>)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, alias])

    return <div>
        {content}
    </div>
}
