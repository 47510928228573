import {instance} from "./api";

export const templateAPI = {

    getTemplateByAlias(alias, options = {}) {
        return instance.post(`/v1/stats/my-template-data/${alias}`, options, {
            headers: {
                "Authorization": "Basic " + localStorage.getItem("auth")
            },
        }).then((response) => {
            return response.data;
        });
    }
};