import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Table, TableTextFilter} from "@consta/uikit/Table";
import {Link, useParams} from "react-router-dom";
import {Button} from "@consta/uikit/Button";
import {IconBackward} from "@consta/uikit/IconBackward";
import {Avatar} from "@consta/uikit/Avatar";
import {useHttp} from "../utils/hooks/http.hook";
import {useSelector} from "react-redux";

export const WebmasterFlows = () => {
    const params = useParams()
    let id = params?.id
    const {request} = useHttp()

    const {profile} = useSelector(state => state.profile)

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        let sellerId = id || profile.id
        let options = {
            "stat": {"periods": {"week": "01.10.2021 - 08.10.2021"}, "filters": {"seller_id": [+sellerId]}},
        }
        const getInfo = async () => {
            let response = await request("/v1/stats/my-template-data/webmaster_flows", "post", options)
            if (response) {
                setData(response)
            }
        }
        if (profile)
            getInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, id])

    const [columns,] = React.useState(
        [
            {
                title: 'Поток',
                renderCell: (item) => <Link to={`/ads-company/${item.flow_id}`}>
                    <Text size="s" view="primary"
                          className="table__text">{item.flow_id} -
                        <br/>{item.flow_domain}</Text>
                </Link>,
                // filterer: TableTextFilter,
                sortable: true,
                accessor: 'flow_id',
                width: 250
            },
            {
                title: 'Данные',
                accessor: 'flow_ind_lead_campaigns',
                width: 202,
                renderCell: (row) => {
                    let isSuccess = Object.keys(row).find(item => item === "ok_ads")
                    return <Text size="s" view={isSuccess ? "success" : "alert"}
                                 className="table__text">{isSuccess ? "Ok" : "Не загрузились"}</Text>
                }
            },
            {
                title: 'Оффер',
                accessor: 'offer',
                width: 202,
                renderCell: () => <div className="table__cell__offer"><Avatar size="s"
                                                                              url="https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"/>
                    <Text style={{width: "80%"}} size="2xs" view="ghost" className="table__text">Соковыжималка для
                        овощей
                        и фруктов 2 в 1 - Delatec</Text></div>
            },
            {
                title: 'Мониторинг',
                renderCell: (item) => <Text weight="bold" size="s" view="linkMinor">{+item.monitoring_ads}</Text>,
                sortable: true,
                accessor: 'monitoring_ads',
            },
            {
                title: 'Проблема',
                renderCell: (item) => <Text weight="bold" size="s" view="alert">{+item.problem_ads}</Text>,
                sortable: true,
                accessor: 'problem_ads',
            },
            {
                title: 'Хорошо',
                renderCell: (item) => <Text weight="bold" size="s" view="success">{+item.ok_ads}</Text>,
                sortable: true,
                accessor: 'ok_ads',
            },
        ])
    const [filters,] = React.useState(
        [
            {
                id: 'id',
                name: 'Месторождение: ',
                field: 'id',
                filterer: (cellValue, filterValues) => {
                    return filterValues.some((filterValue) => filterValue && filterValue.value === cellValue);
                },
                component: {
                    name: TableTextFilter,
                    props: {
                        withSearch: true,
                        items: [
                            {name: 'Северное', value: 'Северное'},
                            {name: 'Южное', value: 'Южное'},
                            {name: 'Западное', value: 'Западное'},
                            {name: 'Восточное', value: 'Восточное'},
                            {name: 'Центральное', value: 'Центральное'},
                        ],
                    },
                },
            }
        ])
    const [rows, setRows] = React.useState(data)

    React.useEffect(() => {
        if (data)
            setRows(data)
    }, [data])

    return (
        <div>
            <div className="flex">
                <Text size="3xl" weight="bold">Потоки и офферы</Text>
                <Link to="/template/teamlead">
                    <Button size="xs" label={<Text className="flex" size="xs">
                        <IconBackward size="xs"/> К списку пользователей</Text>} view="ghost"/>
                </Link>
            </div>
            <Table size="s" filters={filters} columns={columns || []} rows={rows || []}
                   emptyRowsPlaceholder={<Text>Здесь пока нет данных</Text>}/>
        </div>
    );
}
