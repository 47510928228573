import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Table, TableTextFilter} from "@consta/uikit/Table";
import {Avatar} from "@consta/uikit/Avatar";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Button} from "@consta/uikit/Button";
import {useHttp} from "../utils/hooks/http.hook";

export const Users = ({users}) => {
    const templates = useSelector(state => state.app.templates)

    const {request} = useHttp()

    const [teamleadTemplate, setTeamleadTemplate] = React.useState({})
    const [tableUsers, setTableUsers] = React.useState([])

    React.useEffect(() => {
        if (templates) {
            let teamlead = templates.find(item => item.alias === "teamlead")
            setTeamleadTemplate(teamlead.schema)
        }
    }, [templates])

    React.useEffect(() => {
        if (teamleadTemplate && users) {
            let buttons = []

            teamleadTemplate?.groups_buttons && Object.keys(teamleadTemplate?.groups_buttons).forEach(item => {
                return teamleadTemplate?.groups_buttons[item].forEach((button) => {
                    return buttons.push(button)
                })
            })
            let usersEdited = [...users].map(item => ({...item, buttons}))
            setTableUsers(usersEdited)
        }
    }, [users, teamleadTemplate])

    const getInfo = async (button, id) => {
        if(button && id) {
            let stat = {"periods": {"week": "01.10.2021 - 08.10.2021"},"groups": [button.groups], "filters": {[button.filter]: [id]}}
            await request("/v1/stats/my-template-data/teamlead", "post", {stat})
        }
    }

    const [columns,] = React.useState(
        [
            {
                title: 'Пользователь',
                renderCell: (user) => <Link to={`/webmaster_flows/${user.id}`}>
                    <div className="table__cell__offer">
                        <Avatar size="s" url={user.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                        <div style={{marginLeft: "15px"}}>
                            <Text size="s" view="primary"
                                  className="table__text">{user.first_name + " " + (user?.last_name || "")}</Text>
                            {/*<Text size="2xs" view="secondary" className="table__text">{user.roles && Object.keys(user.roles).map((item, i) => i === 0 && user.roles[item])}</Text>*/}
                            <Text size="2xs" view="secondary" className="table__text">{user.email}</Text>
                        </div>
                    </div>
                </Link>,
                filterer: TableTextFilter,
                sortable: true,
                accessor: 'id',
                width: 250
            },
            {
                title: 'Пропущенный отчет',
                renderCell: (user) => <Text weight="bold" size="s" view="linkMinor"
                                            className="table__text">{user?.data?.missedReports}</Text>,
                sortable: true,
                accessor: 'data.missedReports',
            },
            {
                title: 'Мониторинг',
                renderCell: (user) => <Text weight="bold" size="s" view="linkMinor"
                                            className="table__text">{user?.data?.monitoring}</Text>,
                sortable: true,
                accessor: 'data.monitoring',
            },
            {
                title: 'Проблема',
                renderCell: (user) => <Text weight="bold" size="s" view="alert"
                                            className="table__text">{user?.data?.problem}</Text>,
                sortable: true,
                accessor: 'data.problem',
            },
            {
                title: 'Хорошо',
                renderCell: (user) => <Text weight="bold" size="s" view="success"
                                            className="table__text">{user?.data?.good}</Text>,
                sortable: true,
                accessor: 'data.good',
            },
            {
                title: 'Действие',
                renderCell: (user) => <div className="flex">
                    {
                        user?.buttons?.map((button, i) => {
                            return <Button style={{marginLeft: "7px", background: button.color}} size="xs" key={i}
                                           onClick={() => getInfo(button, user.id)} label={button.name}/>
                        })
                    }
                </div>,
                sortable: true,
            },
        ])
    const [filters,] = React.useState(
        [
            {
                id: 'id',
                name: 'Месторождение: ',
                field: 'id',
                filterer: (cellValue, filterValues) => {
                    return filterValues.some((filterValue) => filterValue && filterValue.value === cellValue);
                },
                component: {
                    name: TableTextFilter,
                    props: {
                        withSearch: true,
                        items: [
                            {name: 'Северное', value: 'Северное'},
                            {name: 'Южное', value: 'Южное'},
                            {name: 'Западное', value: 'Западное'},
                            {name: 'Восточное', value: 'Восточное'},
                            {name: 'Центральное', value: 'Центральное'},
                        ],
                    },
                },
            }
        ])
    const [rows, setRows] = React.useState(tableUsers)

    React.useEffect(() => {
        if (tableUsers)
            setRows(tableUsers)
    }, [tableUsers])

    return (
        <div>
            <Text size="3xl" weight="bold"> Пользователи</Text>
            {teamleadTemplate && <Table filters={filters} columns={columns} rows={rows}
                                        emptyRowsPlaceholder={<Text>Здесь пока нет данных</Text>}/>}
        </div>
    );
}
