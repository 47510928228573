import React from "react";
import {Header as HeaderComponent, HeaderButton, HeaderLogin, HeaderMenu, HeaderModule} from "@consta/uikit/Header";
import {NavLink} from "react-router-dom";
import {Text} from "@consta/uikit/Text";
import {IconRing} from "@consta/uikit/IconRing";
import {IconBento} from "@consta/uikit/IconBento";
import {useSelector} from "react-redux";

export const Header = () => {
    const isAuth = useSelector(state => state.auth.isAuth)
    const {profile} = useSelector(state => state.profile)
    const [menuItems, ] = React.useState([{
        label: "Аналитика",
        href: "/",
        active: true
    }, {
        label: "Пункт 2",
        href: "/",
        active: false
    }])

    return <HeaderComponent
        leftSide={
            <>
                <HeaderModule>
                    <NavLink className="logo__link" to="/">
                        <Text weight="black">7leads</Text>
                    </NavLink>
                </HeaderModule>
                <HeaderModule>
                    <HeaderMenu items={menuItems}/>
                </HeaderModule>
            </>
        }
        rightSide={
            <>

                <HeaderModule indent="s">
                    <HeaderButton iconLeft={IconRing} />
                </HeaderModule>
                <HeaderModule indent="s">
                    <HeaderButton iconLeft={IconBento} />
                </HeaderModule>
                <HeaderModule indent="s">
                    <HeaderLogin personAvatarUrl={profile?.avatar || ""} personName={profile?.first_name + " " + profile?.last_name}
                                 personInfo={profile?.email || ""} isLogged={isAuth} />
                </HeaderModule>
            </>
        }
    />
};
