import React from "react";
import {useSelector} from "react-redux";
import {Route, Switch, useHistory} from "react-router-dom";
import {Header} from "./Header";
import {Footer} from "./Footer";
import {routes as routesList} from "../routes"
import {Permission} from "../pages/system/Permission";


export const Layout = () => {
    const history = useHistory()
    const isAuth = useSelector(state => state.auth.isAuth)
    const routes = routesList || []

    React.useEffect(() => {
        if (!isAuth)
            history.push("/login")
    }, [isAuth, history])

    return <>
        <Switch>
            {
                routes.map((item, index) => {
                    let CurrentLayOut = MainLayout;
                    if (item.meta.layout === "empty")
                        CurrentLayOut = EmptyLayout
                    let content = item.render
                    if (item.meta.auth && !isAuth) {
                        content = <Permission/>
                    }
                    return <Route path={item.path} exact={item.exact}
                                  key={index}
                                  render={() => <CurrentLayOut>{content}</CurrentLayOut>}
                    />;
                })
            }
        </Switch>
    </>
};

export const MainLayout = ({children}) => {
    return <div className="content__wrapper">
            <Header/>
            <div className="app__content">
                {children}
            </div>
            <Footer/>
        </div>
};
export const EmptyLayout = ({children}) => {
    return <>
        {children}
    </>
};

