import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Table} from "@consta/uikit/Table";
import {Switch} from "@consta/uikit/Switch";
import {Badge} from "@consta/uikit/Badge";
import {Button} from "@consta/uikit/Button";
import {Sidebar} from "@consta/uikit/Sidebar";
import {Link} from "react-router-dom";
import {IconBackward} from "@consta/uikit/IconBackward";
import {Card} from "@consta/uikit/Card";
import {Avatar} from "@consta/uikit/Avatar";

export const reportStatusNames = {
    "success": "Хорошо",
    "warning": "Требуется отчёт",
    "system": "Отключен",
    "error": "Пропущен отчёт",
    "normal": "Мониторинг",
}

const adTypes = {
    "company_name": "Компания",
    "ads_group": "Группа",
    "ad": "Объявление",
}
export const AdsCompany = () => {

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState({});
    const [columns,] = React.useState([
        {
            title: 'Название',
            sortable: true,
            accessor: 'title',
            renderCell: (row) => <Text onClick={() => {
                if (row.type === "ad") {
                    setIsSidebarOpen(true)
                    setActiveItem(row)
                }
            }} size="s">{row.title}</Text>
        },
        {
            title: 'Тип',
            sortable: true,
            accessor: 'type',
            renderCell: (row) => <Text size="s">{adTypes[row.type]}</Text>
        },
        {
            title: 'Длительность',
            sortable: true,
            accessor: 'duration',
        },
        {
            title: 'Статус',
            sortable: true,
            accessor: 'status',
            renderCell: (row) => <Switch onChange={() => toggleSwitch(row.id)} checked={row.status}/>
        },
        {
            title: 'Отчет',
            sortable: true,
            accessor: 'report',
            renderCell: (row) => <Badge size="s" status={row.reportStatus}
                                        label={reportStatusNames[row.reportStatus]} view="stroked"/>
        },
        {
            title: 'Сегодня',
            sortable: true,
            accessor: 'today',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'Вчера',
            sortable: true,
            accessor: 'yesterday',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: '3 дня назад',
            sortable: true,
            accessor: '3 days ago',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'За 3 дня',
            sortable: true,
            accessor: '3 day',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'За 7 дней',
            sortable: true,
            accessor: '7 day',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        }
    ])

    const [rows, setRows] = React.useState([
        {
            id: 1,
            title: 'Название компании',
            duration: "10 дней",
            type: 'company_name',
            reportStatus: "error",
            today: "error",
            rows: [
                {
                    id: 2,
                    title: 'Группа объявлений',
                    duration: "5 дней",
                    type: 'ads_group',
                    reportStatus: "error",
                    today: "error",
                    rows: [
                        {
                            id: 3,
                            title: 'Название объявления',
                            type: 'ad',
                            duration: "2 дней",
                            reportStatus: "error",
                            today: "error",
                        },
                    ],
                },
            ],
        }
    ])

    // React.useEffect(() => {
    //     // if (data)
    //     // setRows(data)
    // }, [data])

    const toggleSwitch = (id) => {
        setRows(prevState => {
            return prevState?.map(item => {
                if (item.id === id)
                    return {...item, status: !item.status}
                return item
            })
        })
    }

    return (
        <div>
            <div className="flex">
                <Text size="3xl" weight="bold">Объявления</Text>
                <Link to="/template/webmaster_flows">
                    <Button size="xs" label={<Text className="flex" size="xs">
                        <IconBackward size="xs"/>К офферам и потокам</Text>} view="ghost"/>
                </Link>
            </div>
            <Table rows={rows} columns={columns}/>
            <AdSidebar activeItem={activeItem} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
        </div>
    );
}

const AdSidebar = ({isSidebarOpen, setIsSidebarOpen, activeItem}) => {
    const [columns,] = React.useState([
        {
            title: 'Наименование',
            sortable: true,
            accessor: 'title',
            width: 250
        },

        {
            title: 'Сегодня',
            sortable: true,
            accessor: 'today',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'Вчера',
            sortable: true,
            accessor: 'yesterday',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: '3 дня назад',
            sortable: true,
            accessor: '3 days ago',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'За 3 дня',
            sortable: true,
            accessor: '3 day',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'За 7 дней',
            sortable: true,
            accessor: '7 day',
            renderCell: (row) => <Badge className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        }
    ])
    const [rows, setRows] = React.useState([])

    const [isReportSidebarOpen, setIsReportSidebarOpen] = React.useState(false)

    const toggleSwitch = (id) => {
        setRows(prevState => {
            return prevState?.map(item => {
                if (item.id === id)
                    return {...item, status: !item.status}
                return item
            })
        })
    }

    return <>
        <Sidebar
            isOpen={isSidebarOpen}
            size="2/3"
            onClickOutside={() => setIsSidebarOpen(false)}
            onEsc={() => setIsSidebarOpen(false)}
        >
            <Sidebar.Content className="sidebar__content">
                <div className="flex">
                    <Text size="3xl" weight="bold">Объявление</Text>
                    <Button size="xs" label={<Text className="flex" size="xs"
                                                   onClick={() => setIsSidebarOpen(false)}>
                        <IconBackward size="xs"/> Закрыть</Text>} view="ghost"/>
                </div>
                <div className="sidebar__card__wrapper flex-block">
                    <Card className="sidebar__card" verticalSpace="2xl" horizontalSpace="2xl" form="square">
                        <Text weight="bold" size="l">Информация об объявлении</Text>
                        <div className="sidebar__card__content">
                            <div className="sidebar__content__item">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={activeItem?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s" view="primary"
                                              className="table__text">{activeItem?.first_name + " " + (activeItem?.last_name || "")}</Text>
                                        <Text size="2xs" view="secondary"
                                              className="table__text">{activeItem?.email}</Text>
                                    </div>
                                </div>
                                <Badge size="s" status={activeItem?.reportStatus}
                                       label={reportStatusNames[activeItem?.reportStatus]} view="stroked"/>
                            </div>
                            <div className="sidebar__content__item">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={activeItem?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s" view="primary"
                                              className="table__text">{activeItem?.first_name + " " + (activeItem?.last_name || "")}</Text>
                                        <Text size="2xs" view="secondary"
                                              className="table__text">{activeItem?.email}</Text>
                                    </div>
                                </div>
                                <Switch onChange={() => toggleSwitch(activeItem?.id)}
                                        checked={!!activeItem?.status}/>
                            </div>
                            <div className="sidebar__content__item">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={activeItem?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s" view="primary"
                                              className="table__text">{activeItem?.first_name + " " + (activeItem?.last_name || "")}</Text>
                                        <Text size="2xs" view="secondary"
                                              className="table__text">{activeItem?.email}</Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="sidebar__card" verticalSpace="2xl" horizontalSpace="2xl" form="square">
                        <Text weight="bold" size="l">Отчеты и заметки</Text>
                        <div className="sidebar__card__content">
                            <div className="sidebar__card__content__right">
                                <div>
                                    <Text view="secondary" size="xs">Статус отчета:
                                        <Text view={activeItem?.status}>{activeItem?.status} </Text>
                                    </Text>
                                    <Text view="secondary" size="xs">Последняя дата отчета:
                                        <Text view={activeItem?.status}>{activeItem?.status} </Text>
                                    </Text>
                                    <Text view="secondary" size="xs">Кол-во отчетов:
                                        <Text view={activeItem?.status}>{activeItem?.status} </Text>
                                    </Text>
                                </div>
                                <div style={{marginLeft: "25px"}}>
                                    <Button onClick={() => setIsReportSidebarOpen(true)} label="Смотреть" size="xs"
                                            view="ghost"/>
                                    <Button onClick={() => setIsReportSidebarOpen(true)} style={{marginLeft: "10px"}}
                                            label="Написать" size="xs" view="secondary"/>
                                </div>
                            </div>
                            <div className="sidebar__card__content__right">
                                <div>
                                    <Text view="secondary" size="xs">Последняя дата заметки:
                                        <Text view="secondary">{activeItem?.time} </Text>
                                    </Text>
                                    <Text view="secondary" size="xs">Кол-во заметок:
                                        <Text view="secondary">{activeItem?.status} </Text>
                                    </Text>
                                </div>
                                <div style={{marginLeft: "25px"}}>
                                    <Button label="Смотреть" size="xs" view="clear"/>
                                    <Button style={{marginLeft: "10px"}} label="Написать" size="xs" view="ghost"/>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="sidebar__content__statistic">
                    <Text weight="bold" size="l">Статистика</Text>
                    <Table rows={rows} columns={columns}/>
                </div>
            </Sidebar.Content>
        </Sidebar>
        <ReportSidebar isReportSidebarOpen={isReportSidebarOpen} setIsReportSidebarOpen={setIsReportSidebarOpen}/>
    </>
}

const ReportSidebar = ({isReportSidebarOpen, setIsReportSidebarOpen}) => {

    const [items, ] = React.useState([
        {
            type: "alert",
            title: "Системоное уведомление",
            desc: "Автаматическое уведомление",
            date: "11.03.2021 в 8:30",
            action: "Выявление плохой статистики",
            status: "Требуется отчёт"
        },
        {
            type: "success",
            date: "11.03.2021 в 8:30",
            status: "Мониторинг",
            action: "Продлить компанию",
            reportStatus: "Просмотрено",
            comment: "Я верю в данную компанию",
            user: {
                name: "Сейсмия Кернман",
                status: "Просмотрено",
                role: "Веб-мастер"
            }
        },
    ])

    return <Sidebar
        isOpen={isReportSidebarOpen}
        size="m"
        onClickOutside={() => setIsReportSidebarOpen(false)}
        onEsc={() => setIsReportSidebarOpen(false)}
    >
        <Sidebar.Content className="sidebar__content">
            <Text weight="bold" size="l">Отчеты</Text>
            <div className="sidebar__report">
                {
                    items?.map(item => {
                        return item.type === "alert"
                            ? <Card className="sidebar__card type__alert" verticalSpace="m" status="alert" view="alert"
                                    horizontalSpace="2xl" form="square">
                                <Text size="s">{item.title}</Text>
                                <Text size="2xs" view="ghost">{item.desc}</Text>
                                <Text weight="bold" size="xs">Действие</Text>
                                <Text size="xs" view="alert">{item.action}</Text>
                                <Text size="xs" view="secondary">Статус объявления был изменен на - {item.status}</Text>

                                <Text weight="bold" size="2xs" style={{textAlign: "right"}}
                                      view="secondary">{item.date}</Text>
                            </Card>
                            : <Card className={`sidebar__card type__success`} verticalSpace="m" horizontalSpace="2xl" form="square">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={"https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s">{item.user.name}</Text>
                                        <Text size="2xs" view="ghost">{item.user.role}</Text>
                                    </div>
                                </div>
                                <Text weight="bold" size="xs">Действие</Text>
                                <Text size="xs" view="success">{item.action}</Text>
                                <Text weight="bold" size="xs">Комментарий</Text>
                                <Text size="xs" view="secondary">{item.comment}</Text>
                                <Text size="xs" view="secondary">Статус объявления был изменен на - {item.status}</Text>

                                <Text weight="bold" size="2xs" style={{textAlign: "right"}}
                                      view="secondary">{item.date}</Text>
                            </Card>
                    })
                }

            </div>
        </Sidebar.Content>
    </Sidebar>
}
