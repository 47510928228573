import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm, Controller} from "react-hook-form";
import {Redirect} from "react-router-dom";
import {useHttp} from "../utils/hooks/http.hook";
import {TextField} from "@consta/uikit/TextField";
import {Text} from "@consta/uikit/Text";
import {Button} from "@consta/uikit/Button";
import {actionsAuth} from "../redux/auth-reducer";
import {actionsApp} from "../redux/app-reducer";

export const Login = () => {
    const dispatch = useDispatch();

    const isAuth = useSelector(state => state.auth.isAuth)
    const {handleSubmit, formState: {errors}, control} = useForm()
    const {request, loading} = useHttp()

    const onSubmit = async (data) => {
        let authEncrypted = window.btoa(data.email + ":" + data.password)

        try {
            let response = await request("v1/stats/my-templates", "get", {}, {
                "Authorization": "Basic " + authEncrypted
            })
            if (response) {
                dispatch(actionsApp.setTemplates(response))
                dispatch(actionsAuth.setNewAuth(true))
                localStorage.setItem("auth", authEncrypted)
            }
        } catch (e) {
            console.log(e)
        }
    };

    if (isAuth)
        return <Redirect to="/"/>

    return <div className="login">
        <Text weight="bold" size={"2xl"}>Логин</Text>
        <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-body__wrapper">
                <Controller
                    name="email"
                    control={control}
                    // defaultValue="anton.kovalskiy@7leads.biz"
                    defaultValue=""
                    rules={{
                        required: true,
                        pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
                    }}
                    render={({field: {value, onChange}}) => {
                        const onChangeEdited = ({value}) => {
                            onChange(value)
                        }
                        return <TextField onChange={onChangeEdited} value={value} placeholder="Email"/>
                    }}
                />
                {
                    errors["email"] &&
                    <label className={`input-label input-label_display`}>Не валидный email</label>
                }
            </div>
            <div className="input-body__wrapper">
                <Controller
                    name="password"
                    control={control}
                    // defaultValue="betxiv-wiknEq-domju2"
                    defaultValue=""
                    rules={{
                        required: true,
                    }}
                    render={({field: {value, onChange}}) => {
                        const onChangeEdited = ({value}) => {
                            onChange(value)
                        }
                        return <TextField onChange={onChangeEdited} value={value}
                                          placeholder="Введите пароль" type={"password"}/>
                    }}
                />

            </div>
            <Button disabled={loading} className="login-submit__btn" type={"submit"} label="Авторизироваться"/>
        </form>
    </div>
};