import {ProfileAPI} from "../api/profileAPI";
import {actionsAuth} from "./auth-reducer";
import {actionsProfile} from "./profile-reducer";

let initialState = {
    initialized: false, //initialize app,
    templates: [],
    appSettings: {
        lang: null, //"en"
        theme: null, //"dark"
    }
};
let appReducer = (state = initialState, action) => {

    switch (action.type) {
        case "APP/INIT": {
            return {
                ...state,
                initialized: true
            };
        }
        case "APP/SET_TEMPLATES": {
            return {
                ...state,
                templates: action.payload
            };
        }
        default:
            return state;
    }
};

export const actionsApp = {
    initialize: () => ({
        type: "APP/INIT"
    }),
    setTemplates: (templates) => ({
        type: "APP/SET_TEMPLATES",
        payload: templates
    })
};

export const init = () => async (dispatch) => {
    let templates = dispatch(getTemplates())
    let userInfo = dispatch(getUserInfo())
    Promise.all([userInfo, templates]).then(() => dispatch(actionsApp.initialize()));
};

export default appReducer;

export const getTemplates = () => async (dispatch, getState) => {
    const isAuth = getState().auth.isAuth
    let auth = localStorage.getItem("auth")
    if (auth)
        try {
            let response = await ProfileAPI.getTemplates(auth)
            if (response) {
                dispatch(actionsApp.setTemplates(response))
                !isAuth && dispatch(actionsAuth.setNewAuth(true))
            }
        } catch (e) {
        }
}
export const getUserInfo = () => async (dispatch, getState) => {
    const isAuth = getState().auth.isAuth
    let auth = localStorage.getItem("auth")
    if (auth)
        try {
            let response = await ProfileAPI.getUserInfo(auth)
            if (response) {
                let profile = {
                    avatar: response.avatar,
                    email: response.email,
                    roles: response.roles,
                    id: response.id,
                    first_name: response?.profile.first_name,
                    last_name: response?.profile.last_name,
                    middle_name: response?.profile.middle_name,
                    data: {
                        monitoring: 0,
                        missedReports: 0,
                        problem: 0,
                        good: 0
                    }
                }
                dispatch(actionsProfile.setProfile(profile))
                !isAuth && dispatch(actionsAuth.setNewAuth(true))
            }
        } catch (e) {
        }
}
