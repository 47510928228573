import {authAPI} from "../api/authAPI";
import {actionsProfile} from "./profile-reducer";

const initialState = {
    isAuth: false,
    isFetching: false
}

const authReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'AUTH/SET_NEW_AUTH': {
            return {
                ...state,
                isAuth: action.isAuth,
                userId: action.userId
            }
        }

        case 'AUTH/SET_IS_FETCHING': {
            return {
                ...state,
                isFetching: action.payload,
            }
        }
        default:
            return state
    }
}

export const actionsAuth = {
    setNewAuth: (isAuth) => ({
        type: 'AUTH/SET_NEW_AUTH',
        isAuth
    }),
    logout: () => ({
        type: 'AUTH/SET_NEW_AUTH',
        isAuth: false,
    }),
    setIsFetching: (payload) => ({
        type: 'AUTH/SET_IS_FETCHING',
        payload
    })
}

export const checkAuthMe = () => async (dispatch) => {
    try {
        dispatch(actionsAuth.setIsFetching(true))
        let response = await authAPI.checkAuth()
        if (response) {
            let token = localStorage.getItem("access_token")
            token && dispatch(actionsAuth.setNewAuth(token, true, null))
            dispatch(actionsProfile.setProfile(response))
            dispatch(actionsAuth.setIsFetching(false))
        }
    } catch (e) {
        let token = localStorage.getItem("access_token")
        dispatch(actionsAuth.setIsFetching(false))
        let refreshToken = localStorage.getItem("refresh_token")
        if (token && refreshToken) {
            let response = await authAPI.refreshToken(refreshToken)
            if (response) {
                localStorage.setItem("access_token", response.access)
            }
        }
    }
}

export const logout = () => async (dispatch) => {
    dispatch(actionsAuth.logout())
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
}

export default authReducer